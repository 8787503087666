import { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// Importing all the pages components
import JobPosts from '../routes/JobPosts'
import Login from '../routes/Login'
import SignUp from '../routes/SignUp'
import NotFound from '../routes/NotFound/NotFound'
import JobDetail from '../routes/JobPosts/JobDetail'
import Support from '../routes/Support'
import MultipleResumesJobPosts from '../routes/MultipleResumesJobPosts'
import MultipleResumesJobDetails from '../routes/MultipleResumesJobPosts/MultipleResumesJobDetail'
// Importing the useAuthState hook
import { useAuthState } from 'react-firebase-hooks/auth'
// Imoprting auth from firebase
import { auth, db } from '../firebase'
import { ROUTES } from '../constants/routes'
import { AnonymousJobsCTX } from '../contexts/anonymousJobs'
import { arrayUnion, doc, setDoc } from 'firebase/firestore'
import { COLLECTION_NAMES } from '../constants/collections'

import Referral from '../routes/Referral'
import Pricing from '../routes/Pricing'

import Resumes from '../routes/Resumes'
import ResumeBuilder from '../routes/Resumes/ResumeBuilder'
import AuthLayout from '../components/AuthLayout'
import Alternatives from '../routes/Alternatives'
import Welcome from '../routes/Welcome'
import MainLoading from '../components/MainLoading'
import TermsAndConditions from '../routes/TermsAndConditions'
import FAQ from '../routes/FAQ'
import AboutUs from '../routes/AboutUs'
import AppContentWrapper from '../components/AppWrapper/AppContentWrapper'
import CoverLetters from '../routes/CoverLetters'
import CoverLetterBuilder from '../routes/CoverLetters/CoverLetterBuilder'
import AddJob from '../routes/JobPosts/AddJob'
import AddMultiResumeJob from '../routes/MultipleResumesJobPosts/AddMultiResumeJob'
import Services from '../routes/Services'
import Blogs from '../routes/Blogs'
import PrivateRoutes from './PrivateRoutes'
import NonPrivateRoutes from './NonPrivateRoutes'
import Loader from '../components/Loader'
import { LoaderContext } from '../contexts/loader-context'
import BlogBuilder from '../routes/BlogBuilder'
import AddBlog from '../routes/BlogBuilder/AddBlog'
import EditBlog from '../routes/BlogBuilder/EditBlog'
import GhostJobs from '../routes/GhostJobs'
import GhostJobBuilder from '../routes/GhostJobs/GhostJobBuilder'
import GhostJobDetail from '../routes/GhostJobs/GhostJobDetail'

const AppRouter = () => {
	const [user, loading] = useAuthState(auth)
	const { hasAnonymousJobs, setHasAnonymousJobs, anonymousJobs, setAnonymousJobs } = useContext(AnonymousJobsCTX)
	const { showLoading } = useContext(LoaderContext)
	useEffect(() => {
		if (loading) return
		if (user !== null) {
			if (!user?.isAnonymous) {
				if (hasAnonymousJobs) {
					const jobDoc = {
						uid: user?.uid ?? '',
						email: user?.email ?? 'anonymous',
						displayName: user?.displayName ?? 'anonymous',
						sharedWith: arrayUnion(user?.email ?? 'anonymous')
					}
					const promises = anonymousJobs?.map(async (jobId) => {
						return await setDoc(doc(db, COLLECTION_NAMES.JOBS_COLLECTION, jobId), jobDoc, { merge: true })
					})
					;(async () => {
						await Promise.all(promises ?? [])

						setHasAnonymousJobs && setHasAnonymousJobs(false)
						setAnonymousJobs && setAnonymousJobs([])
					})()
				}
			}
		}
	}, [user, loading])
	const isAuthenticated = Boolean(user)
	return (
		<>
			{showLoading ? <Loader /> : null}
			{user === null && loading ? (
				<MainLoading />
			) : (
				<>
					<AppContentWrapper>
						<Routes>
							<Route element={<NonPrivateRoutes isAuthenticated={isAuthenticated} />}>
								<Route path={ROUTES.LOGIN} element={<Login />} />
								<Route path={ROUTES.SIGNUP} element={<SignUp />} />
							</Route>
							<Route element={<PrivateRoutes isAuthenticated={isAuthenticated} />}>
								<Route path={ROUTES.OVERVIEW} element={<AuthLayout />}>
									<Route index element={<JobPosts />} />
									<Route path={ROUTES.JOB_DETAIL} element={<JobDetail />} />
									<Route path={ROUTES.JOBS} element={<JobPosts />} />
									<Route path={ROUTES.ADD_JOB} element={<AddJob />} />
									<Route path={ROUTES.GHOST_JOBS} element={<GhostJobs />} />
									<Route path={ROUTES.GHOST_JOBS_DETAIL} element={<GhostJobDetail />} />
									<Route path={ROUTES.GHOST_JOB_BUILDER} element={<GhostJobBuilder />} />
									<Route path={ROUTES.SUPPORT} element={<Support />} />
									<Route path={ROUTES.RESUMES} element={<Resumes />} />
									<Route path={ROUTES.RESUME_BUILDER} element={<ResumeBuilder />} />
									<Route path={ROUTES.COVER_LETTER} element={<CoverLetters />} />
									<Route path={ROUTES.COVER_LETTER_BUILDER} element={<CoverLetterBuilder />} />
									<Route path={ROUTES.MULTIPLE_RESUMES_JOBS_DETAIL} element={<MultipleResumesJobDetails />} />
									<Route path={ROUTES.MULTIPLE_RESUMES_JOBS} element={<MultipleResumesJobPosts />} />
									<Route path={ROUTES.ADD_MULTIPLE_RESUMES_JOBS} element={<AddMultiResumeJob />} />
									<Route path={ROUTES.BLOG_BUILDER} element={<BlogBuilder />} />
									<Route path={ROUTES.BLOG_BUILDER + '/:id'} element={<EditBlog />} />
									<Route path={ROUTES.ADD_BLOG_BUILDER} element={<AddBlog />} />
								</Route>
							</Route>
							<Route path={ROUTES.REFERRAL} element={<Referral />} />
							<Route path={ROUTES.ALTERNATIVES} element={<Alternatives />} />
							<Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
							<Route path={ROUTES.FAQ} element={<FAQ />} />
							<Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
							<Route path={ROUTES.PRICING} element={<Pricing />} />
							<Route path={ROUTES.SERVICES} element={<Services />} />
							<Route path={ROUTES.SERVICE_DETAIL} element={<Services />} />
							<Route path={ROUTES.BLOGS} element={<Blogs />} />
							<Route path={ROUTES.BLOGS_DETAILS} element={<Blogs />} />
							<Route path={ROUTES.HOME} element={<Welcome />} />
							<Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
						</Routes>
					</AppContentWrapper>
				</>
			)}
		</>
	)
}
export default AppRouter
