import { addDoc, collection, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { COLLECTION_NAMES } from '../constants/collections'
import { toast } from 'react-toastify'
import { NavigateFunction } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { User } from 'firebase/auth'
import { FormValues } from '../components/GhostJobs/AddGhostJob/Form/constants'

const saveNewGhostJob = async (data: FormValues, user: User, navigate: NavigateFunction) => {
	if (data.jobTitle === '' || data.jobDescription === '') return

	try {
		const { jobTitle, jobDescription, postDate, jobSource, salaryRange, salaryType, companyName, companySize, salaryUnknown, companyUnknown, companySizeUnknown } = data

		const formattedData = {
			job_title: jobTitle,
			job_description: jobDescription,
			post_date: postDate.format('YYYY-MM-DD'),
			job_source: jobSource,
			company_name: companyUnknown ? null : companyName,
			salary_range: salaryUnknown ? null : salaryRange,
			salary_type: salaryUnknown ? null : salaryType,
			company_size: companySizeUnknown ? null : companySize,
			company_unknown: companyUnknown,
			company_size_unknown: companySizeUnknown,
			salary_unknown: salaryUnknown,
			isCalculate: true
		}
		const doc = await addDoc(collection(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.GHOST_JOBS), formattedData)

		const unsubscribe = onSnapshot(doc, () => {
			unsubscribe()
			navigate(`${ROUTES.GHOST_JOBS}/${doc.id}`)
		})
	} catch (error) {
		toast.error('There was an error in saving ghost job. Try Again')
	}
}

const editGhostJob = async (data: FormValues, user: User, id: string) => {
	if (data.jobTitle === '' || data.jobDescription === '') {
		return
	}

	try {
		const { jobTitle, jobDescription, postDate, companyName, jobSource, salaryRange, salaryType, companySize, salaryUnknown, companyUnknown, companySizeUnknown } = data

		const formattedData = {
			job_title: jobTitle,
			job_description: jobDescription,
			post_date: postDate.format('YYYY-MM-DD'),
			job_source: jobSource,
			company_name: companyName ?? null,
			salary_range: salaryUnknown ? null : salaryRange,
			salary_type: salaryType ?? null,
			company_size: companySize ?? null,
			company_unknown: companyUnknown,
			company_size_unknown: companySizeUnknown,
			salary_unknown: salaryUnknown,
			isCalculate: true
		}
		const docRef = doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.GHOST_JOBS, id)
		await setDoc(docRef, formattedData)

		return docRef
	} catch (error) {
		toast.error('There was an error in saving ghost job. Try Again')
	}
}

export { saveNewGhostJob, editGhostJob }
