import { FC } from 'react'
import AddGhostJob from '../../../components/GhostJobs/AddGhostJob/AddGhostJob'

const GhostJobBuilder: FC = () => {
	return (
		<>
			<AddGhostJob />
		</>
	)
}

export default GhostJobBuilder
