import { Box, Button, TextField, useMediaQuery } from '@mui/material'
import { FC, useState } from 'react'
import dayjs from 'dayjs'
import { FormValues, JobSourcesEnum, jobSources, SalaryTypesEnum } from './constants'
import { ROUTES } from '../../../../constants/routes'
import { useNavigate } from 'react-router'
// import { onSnapshot } from 'firebase/firestore'
import { CheckboxElement, Controller, FormContainer, SelectElement, useForm } from 'react-hook-form-mui'

import FormInputWithValidation from './FormInputWithValidation'
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import FormInputLabel from './FormInputLabel'
import { CustomTextFieldSX } from '../../../AddJobDialog/CustomTextField'
import SalaryInput from './SalaryInput'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../../firebase'
import { onSnapshot } from 'firebase/firestore'
import theme from '../../../../theme'

interface props {
	saveFunction: Function
	job_title?: string
	job_description?: string
	company_name?: string | null
	company_unknown?: boolean
	company_size?: number | null
	company_size_unknown?: boolean
	post_date?: string
	job_source?: JobSourcesEnum
	salary_unknown?: boolean
	salary_range?: { min: number; max: number } | null
	salary_type?: SalaryTypesEnum | null
	existing?: boolean
}

const GhostJobForm: FC<props> = (props) => {
	const tomorrow = dayjs().add(1, 'day')
	const minDate = dayjs().subtract(70, 'day')
	const [isDisabled, setIsDisabled] = useState(false)
	const [user] = useAuthState(auth)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const formContext = useForm<FormValues>({
		defaultValues: {
			companyName: props.company_name ?? '',
			companyUnknown: props.company_unknown ?? false,
			jobDescription: props.job_description ?? '',
			jobTitle: props.job_title ?? '',
			salaryUnknown: props.salary_unknown ?? false,
			companySizeUnknown: props.company_size_unknown ?? false,
			companySize: props.company_size ?? undefined,
			jobSource: props.job_source ?? undefined,
			postDate: props.post_date ? dayjs(props.post_date) : undefined,
			salaryRange: props.salary_range ?? undefined,
			salaryType: props.salary_type ?? undefined
		}
	})

	const { formState, watch, control } = formContext

	const companyUnknown = watch('companyUnknown')
	const companySizeUnknown = watch('companySizeUnknown')

	const { errors } = formState

	const navigate = useNavigate()

	const onSubmit = async (data: FormValues) => {
		if (isDisabled) return

		if (user) {
			setIsDisabled(true)
			try {
				const docRef = await props.saveFunction(data, user)

				if (props.existing) {
					let count = 0
					const unsubscribe = onSnapshot(docRef, () => {
						if (count > 0) {
							unsubscribe()
							setIsDisabled(false)
						}
						count++
					})
				}
			} catch (error) {
				console.log(error)
			}
		}
	}

	return (
		<FormContainer formContext={formContext} onSuccess={onSubmit}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { md: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '30px', xs: '10px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' },
						marginBottom: { sm: '0px', xs: '10px' }
					}}
				>
					<FormInputWithValidation
						name="companyName"
						label="Company Name"
						placeholder="Company Name"
						disabled={companyUnknown}
						error={!errors.companyName}
						helperText={errors?.companyName?.message ?? ''}
						required={!companyUnknown}
						rules={{
							required: 'Company Name is required'
						}}
					/>
					<CheckboxElement name="companyUnknown" label="Company Name Unknown" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' },
						marginBottom: { sm: '0px', xs: '10px' }
					}}
				>
					<FormInputWithValidation
						name="companySize"
						label="Company Size"
						type="number"
						placeholder="0"
						disabled={companySizeUnknown}
						error={!errors.companySize}
						helperText={errors?.companySize?.message ?? ''}
						required={!companySizeUnknown}
						rules={{
							required: 'Company Size is required',
							valueAsNumber: true
						}}
					/>

					<CheckboxElement name="companySizeUnknown" label="Company Size Unknown" />
				</Box>
			</Box>
			<Box
				sx={{
					marginBottom: { md: '48px', sm: '20px' },
					marginTop: { sm: '0', xs: '15px' }
				}}
			>
				<FormInputWithValidation
					name="jobTitle"
					label="Job Title"
					placeholder="Job Title"
					error={!errors.jobTitle}
					helperText={errors?.jobTitle?.message ?? ''}
					required
					rules={{
						required: 'Job Title is required'
					}}
				/>
			</Box>
			<Box
				sx={{
					marginBottom: { md: '48px', sm: '20px' },
					marginTop: { sm: '0', xs: '15px' }
				}}
			>
				<FormInputWithValidation
					name="jobDescription"
					label="Job Description"
					placeholder="Job Description"
					error={!errors.jobDescription}
					helperText={errors?.jobDescription?.message ?? ''}
					required
					rules={{
						required: 'Job Description is required'
					}}
					rows={10}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '30px', xs: '10px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' },
						marginBottom: { sm: '0px', xs: '10px' }
					}}
				>
					<FormInputLabel label="Post Date" />
					<Controller
						control={control}
						name="postDate"
						rules={{ required: 'Post Date is required' }}
						render={({ field, fieldState: { error } }) => {
							return (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									{isMobile ? (
										<MobileDatePicker
											label="Post Date"
											inputRef={field.ref}
											value={field.value ?? null}
											renderInput={(props) => <TextField required onKeyDown={(e) => e.preventDefault()} fullWidth {...props} error={!!error} helperText={error?.message} />}
											minDate={minDate}
											maxDate={tomorrow}
											onChange={(date) => {
												field.onChange(date)
											}}
										/>
									) : (
										<DatePicker
											label="Post Date"
											inputRef={field.ref}
											value={field.value ?? null}
											renderInput={(props) => <TextField required onKeyDown={(e) => e.preventDefault()} fullWidth {...props} error={!!error} helperText={error?.message} />}
											minDate={minDate}
											maxDate={tomorrow}
											onChange={(date) => {
												field.onChange(date)
											}}
										/>
									)}
								</LocalizationProvider>
							)
						}}
					/>
				</Box>
				<Box
					sx={{
						width: '100%',
						marginLeft: { md: '42px', sm: '20px', xs: '0px' },
						marginBottom: { sm: '0px', xs: '10px' }
					}}
				>
					<FormInputLabel label="Job Source" />
					<SelectElement sx={CustomTextFieldSX} label="Job Source" name="jobSource" options={jobSources} rules={{ required: 'Job Source is required' }} required />
				</Box>
			</Box>
			<SalaryInput />
			<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
				<Button
					disabled={isDisabled}
					sx={{
						cursor: isDisabled ? 'not-allowed' : 'pointer',
						textTransform: 'none',
						borderRadius: '12px',
						marginLeft: '5px',
						padding: {
							md: '16px 24px;',
							sm: '10px 16px;',
							sx: '8px 16px'
						},
						fontSize: { md: '18px', xs: '16px' },
						fontWeight: '600',
						lineHeight: { sm: '27px', xs: '24px' },
						letterSpacing: '2%',
						width: { xs: '50%', sm: 'unset' },
						marginY: '15px'
					}}
					variant="contained"
					color="info"
					type="submit"
				>
					{props.existing ? 'Analyze Again' : 'Is it a Ghost Job?'}
				</Button>
				{props.existing && (
					<Button
						onClick={() => {
							navigate(ROUTES.GHOST_JOB_BUILDER)
						}}
						sx={{
							cursor: isDisabled ? 'not-allowed' : 'pointer',
							textTransform: 'none',
							borderRadius: '12px',
							marginLeft: '5px',
							padding: {
								md: '16px 24px;',
								sm: '10px 16px;',
								sx: '8px 16px'
							},
							fontSize: { md: '18px', xs: '16px' },
							fontWeight: '600',
							lineHeight: { sm: '27px', xs: '24px' },
							letterSpacing: '2%',
							width: { xs: '50%', sm: 'unset' },
							marginY: '15px'
						}}
						variant="contained"
						color="info"
					>
						New Ghost Job Analysis
					</Button>
				)}
			</Box>
		</FormContainer>
	)
}

export default GhostJobForm
