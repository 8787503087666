import { Box } from '@mui/material'
import { FormValues, salaryTypes } from './constants'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CheckboxElement, SelectElement, TextFieldElement } from 'react-hook-form-mui'
import FormInputLabel from './FormInputLabel'
import { CustomTextFieldSX } from '../../../AddJobDialog/CustomTextField'
import CustomPriceInputFormat from '../../../CustomPriceInputFormat'

const SalaryInput: FC = () => {
	const {
		watch,
		formState: { errors },
		control
	} = useFormContext<FormValues>()
	const salaryUnknown = watch('salaryUnknown')
	const salaryMin = watch('salaryRange.min')

	return (
		<Box
			sx={{
				marginBottom: { sm: '0px', xs: '10px' }
			}}
		>
			<FormInputLabel label="Salary Range" />
			<CheckboxElement name="salaryUnknown" label="Salary Unknown" />
			<Box
				sx={{
					display: 'flex',
					flexDirection: { md: 'row', xs: 'column' }
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: { sm: 'row', xs: 'column' },
						marginBottom: { md: '48px', sm: '30px', xs: '10px' },
						width: '100%'
					}}
				>
					<Box sx={{ ...CustomTextFieldSX, m: 1 }}>
						<Controller
							control={control}
							name="salaryRange.min"
							render={({ field: { onChange, name, value } }) => {
								return (
									<TextFieldElement
										sx={CustomTextFieldSX}
										name={name}
										label="Minimum"
										required={!salaryUnknown}
										disabled={salaryUnknown}
										error={!errors.salaryRange?.min}
										helperText={errors?.salaryRange?.min?.message ?? ''}
										rules={
											{
												required: 'Salary Range minimum is required',
												valueAsNumber: true,
												min: {
													value: 7.5,
													message: 'Minimum wage is 7.5'
												}
											} as any
										}
										onChange={(e) => onChange(Number(e.target.value.replaceAll(',', '')))}
										value={value}
										slotProps={{
											input: {
												inputComponent: CustomPriceInputFormat
											}
										}}
									/>
								)
							}}
						/>
					</Box>
					<Box sx={{ ...CustomTextFieldSX, m: 1 }}>
						<Controller
							control={control}
							name="salaryRange.max"
							render={({ field: { onChange, name, value } }) => {
								return (
									<TextFieldElement
										sx={CustomTextFieldSX}
										name={name}
										label="Maximum"
										required={!salaryUnknown}
										disabled={salaryUnknown}
										error={!errors.salaryRange?.max}
										helperText={errors?.salaryRange?.max?.message ?? ''}
										rules={
											{
												required: 'Salary Range maximum is required',
												valueAsNumber: true,
												min: {
													value: salaryMin,
													message: 'Maximum can not be less than minimum'
												}
											} as any
										}
										onChange={(e) => onChange(Number(e.target.value.replaceAll(',', '')))}
										value={value}
										slotProps={{
											input: {
												inputComponent: CustomPriceInputFormat
											}
										}}
									/>
								)
							}}
						/>
					</Box>
					{/* <FormInputWithValidation
						name="salaryRange.min"
						label="Minimum"
						isPrice
						type="number"
						placeholder="0"
						required={!salaryUnknown}
						disabled={salaryUnknown}
						error={!errors.salaryRange?.min}
						helperText={errors?.salaryRange?.min?.message ?? ''}
						rules={{
							required: 'Salary Range minimum is required',
							valueAsNumber: true,
							min: {
								value: 7.5,
								message: 'Minimum wage is 7.5'
							}
						}}
						noLabel
						sx={{ m: 1 }}
					/> */}
					{/* <FormInputWithValidation
						sx={{ m: 1 }}
						isPrice
						type="number"
						name="salaryRange.max"
						label="Maximum"
						placeholder="0"
						required={!salaryUnknown}
						disabled={salaryUnknown}
						error={!errors.salaryRange?.max}
						helperText={errors?.salaryRange?.max?.message ?? ''}
						rules={{
							required: 'Salary Range maximum is required',
							valueAsNumber: true,
							min: {
								value: salaryMin,
								message: 'Maximum can not be less than minimum'
							}
						}}
						noLabel
					/> */}
				</Box>
				<Box
					sx={{
						width: '250px',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<SelectElement
						sx={{ ...CustomTextFieldSX, m: 1 }}
						error={!errors.salaryType}
						helperText={errors?.salaryType?.message ?? ''}
						label="Salary Type"
						name="salaryType"
						options={salaryTypes}
						rules={{ required: 'Salary Type is required' }}
						required={!salaryUnknown}
						disabled={salaryUnknown}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default SalaryInput
