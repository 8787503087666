import { Box, CircularProgress, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../constants/routes'
import { Phishing } from '@mui/icons-material'
import { FC } from 'react'
import { ghostThreshold } from '../GhostJobDetail/constants'

interface props {
	jobTitle: string
	jobId: string
	ghostPercentage: number
	postDate: string
	companyName?: string
}

const DesktopGhostJobBar: FC<props> = ({ jobTitle, jobId, companyName, ghostPercentage, postDate }) => {
	const navigate = useNavigate()
	return (
		<Box
			onClick={() => navigate(ROUTES.GHOST_JOBS_DETAIL.replace(':id', jobId ?? ''))}
			sx={{
				borderRadius: '20px',
				width: '100%',
				height: '92px',
				background: 'white',
				marginBottom: '10px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				cursor: 'pointer',
				// border: "1px solid #000000",
				overflowX: 'auto'
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box
					sx={{
						backgroundColor: '#0062FF',
						borderRadius: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '38px',
						height: '38px',
						marginLeft: '22px',
						color: 'white'
					}}
				>
					<Phishing color="inherit" />
				</Box>
				<Box sx={{ marginX: '20px' }}>
					<Typography
						variant="body1"
						sx={{
							color: '#171725',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
							letterSpacing: '0.1px',
							marginBottom: '6px'
						}}
					>
						Job Title
					</Typography>
					<Typography
						variant="body1"
						sx={{
							color: '#44444F',
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '21px',
							letterSpacing: '0.1px',
							whiteSpace: 'nowrap',
							width: { sm: '100px', md: '150px', lg: '300px' },
							overflow: 'hidden',
							textOverflow: 'ellipsis'
							// border: "1px solid #44444F",
						}}
					>
						{jobTitle}
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<Box
					sx={{
						paddingX: { lg: '30px', xs: '18px' },
						borderLeft: '1px solid #F1F1F5',
						borderRight: '1px solid #F1F1F5',
						width: { xs: '130px', lg: '200px', xl: '260px' }
					}}
				>
					<Typography
						sx={{
							fontWeight: '400',
							fontSize: { lg: '14px', xs: '12px' },
							lineHeight: '16.4px',
							letterSpacing: '0.1px',
							marginBottom: '10px',
							color: '#696974'
						}}
					>
						Company
					</Typography>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: { lg: '16px', xs: '14px' },
							lineHeight: '24px',
							letterSpacing: '0.1px',
							color: '#171725',
							whiteSpace: 'nowrap',
							width: '100%',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
							// border: "1px solid #44444F",
						}}
					>
						{companyName ?? ''}
					</Typography>
				</Box>
				<Box
					sx={{
						paddingX: { lg: '30px', xs: '18px' },
						borderLeft: '1px solid #F1F1F5',
						borderRight: '1px solid #F1F1F5',
						width: { xs: '130px', lg: '200px' }
					}}
				>
					<Typography
						sx={{
							fontWeight: '400',
							fontSize: { lg: '14px', xs: '12px' },
							lineHeight: '16.4px',
							letterSpacing: '0.1px',
							marginBottom: '10px',
							color: '#696974'
						}}
					>
						Post Date
					</Typography>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: { lg: '16px', xs: '14px' },
							lineHeight: '24px',
							letterSpacing: '0.1px',
							color: '#171725',
							whiteSpace: 'nowrap',
							width: '100%',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
							// border: "1px solid #44444F",
						}}
					>
						{postDate ?? ''}
					</Typography>
				</Box>
				<Box
					sx={{
						paddingX: { lg: '30px', xs: '18px' },
						borderLeft: '1px solid #F1F1F5',
						borderRight: '1px solid #F1F1F5'
					}}
				>
					<Typography
						sx={{
							fontWeight: '400',
							fontSize: { lg: '14px', xs: '12px' },
							lineHeight: '16.4px',
							letterSpacing: '0.1px',
							marginBottom: '10px',
							color: '#696974'
						}}
					>
						Percentage
					</Typography>
					{ghostPercentage && (
						<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<CircularProgress
								thickness={3}
								variant="determinate"
								color={ghostPercentage > ghostThreshold ? 'error' : 'success'}
								value={parseFloat(ghostPercentage.toFixed(1))}
								size={50}
							/>
							<Box
								sx={{
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<Typography variant="caption" component="div" color={ghostPercentage > ghostThreshold ? 'error' : 'success'} fontSize={8} fontWeight={'bold'}>
									{`${ghostPercentage.toFixed(1)}%`}
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default DesktopGhostJobBar
