import { ArrowLeft } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useRef } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { container, item } from '../../JobDetailComponents/JobConstants'
import { motion } from 'framer-motion'
import { ROUTES } from '../../../constants/routes'
import GhostJobForm from '../AddGhostJob/Form/GhostJobForm'
import { User } from 'firebase/auth'
import { editGhostJob } from '../../../utils/ghostJobs'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { auth, db } from '../../../firebase'
import { COLLECTION_NAMES } from '../../../constants/collections'
import { useAuthState } from 'react-firebase-hooks/auth'
import { toast } from 'react-toastify'
import { ghostThreshold } from './constants'
import { FormValues } from '../AddGhostJob/Form/constants'

const SingleGhostJobDetail: FC = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	if (id === undefined) return <Navigate to={ROUTES.GHOST_JOBS} />

	const [user, userLoading] = useAuthState(auth)
	// const [initialLoad, setInitialLoad] = useState(false)

	if (userLoading) return <CircularProgress />
	if (!userLoading && !user) {
		toast.error('User not logged in')
		return <Navigate to={ROUTES.LOGIN} />
	}

	const [ghostJobDocData, loading, error] = useDocumentData(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user?.uid ?? '', COLLECTION_NAMES.GHOST_JOBS, id))

	const editFunction = useCallback(async (data: FormValues, user: User) => {
		return await editGhostJob(data, user, id)
	}, [])

	if (error) {
		toast.error('Job not found')
		return <Navigate to={ROUTES.GHOST_JOBS} />
	}
	if (!loading && ghostJobDocData === undefined) {
		toast.error('Job not found')
		return <Navigate to={ROUTES.GHOST_JOBS} />
	}

	const percentageDiv = useRef<HTMLElement>(null)

	useEffect(() => {
		if (!ghostJobDocData) return
		if (!ghostJobDocData.ghost_percentage) return
		// if (!initialLoad) {
		// 	setInitialLoad(true)
		// 	return
		// }
		if (!percentageDiv.current) return
		percentageDiv.current.scrollIntoView({ behavior: 'smooth' })
	}, [ghostJobDocData?.ghost_percentage])

	if (ghostJobDocData)
		return (
			<Box
				sx={{
					position: 'relative',
					minHeight: 'calc(100vh - 60px)',
					backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
					width: '100%'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							paddingX: { lg: '40px', sm: '20px', xs: '12px' },
							flex: 'auto'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: '30px 0 20px 0'
							}}
						>
							<Box
								onClick={() => navigate(ROUTES.GHOST_JOBS)}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									backgroundColor: '#171725',
									boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
									borderRadius: '50%',
									cursor: 'pointer',
									width: { lg: '46px', sm: '35px', xs: '30px' },
									height: { lg: '46px', sm: '35px', xs: '30px' }
								}}
							>
								<ArrowLeft />
							</Box>
							<Typography
								variant="h2"
								sx={{
									fontWeight: '600',
									fontSize: { lg: '24px', md: '20px', xs: '17px' },
									lineHeight: '36px',
									letterSpacing: '0.1px',
									marginLeft: { sm: '18px', xs: '12px' },
									color: '#171725'
								}}
							>
								{ghostJobDocData.job_title}
							</Typography>
						</Box>
						<motion.div variants={container} initial="hidden" animate="show">
							<Box component={motion.div} variants={item}>
								<Box
									sx={{
										backgroundColor: 'white',
										bordeRadius: 20,
										margintop: 19,
										width: '100%',
										padding: '40px',
										paddingBottom: '0px',
										borderRadius: '20px',
										marginBottom: '15px'
									}}
								>
									<GhostJobForm {...ghostJobDocData} saveFunction={editFunction} existing={true} />
									{ghostJobDocData.ghost_percentage && (
										<Box ref={percentageDiv} sx={{ display: 'flex', justifyContent: 'center', paddingY: '15px', flexDirection: 'column', alignItems: 'center' }}>
											<Typography
												variant="h2"
												sx={{
													fontWeight: 'bolder',
													fontSize: { lg: '24px', md: '20px', xs: '17px' },
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginLeft: { sm: '18px', xs: '12px' },
													color: '#171725'
												}}
											>
												Ghost Percentage
											</Typography>
											<Box sx={{ position: 'relative', display: 'inline-block' }}>
												<CircularProgress
													thickness={5}
													variant="determinate"
													color={ghostJobDocData.ghost_percentage > ghostThreshold ? 'error' : 'success'}
													value={parseFloat(ghostJobDocData.ghost_percentage.toFixed(1))}
													size={200}
												/>
												<Box
													sx={{
														top: 0,
														left: 0,
														bottom: 0,
														right: 0,
														position: 'absolute',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center'
													}}
												>
													<Typography
														variant="caption"
														component="div"
														color={ghostJobDocData.ghost_percentage > ghostThreshold ? 'error' : 'success'}
														fontSize={35}
														fontWeight={'bold'}
													>
														{`${ghostJobDocData.ghost_percentage.toFixed(1)}%`}
													</Typography>
												</Box>
											</Box>
										</Box>
									)}
								</Box>
							</Box>
						</motion.div>
					</Box>
				</Box>
			</Box>
		)
}

export default SingleGhostJobDetail
