export const COLLECTION_NAMES = {
	JOBS_COLLECTION: 'Jobs',
	USERS_COLLECTION: 'users',
	MAIL_COLLECTION: 'mail',
	NOTIFICATIONS_COLLECTION: 'Notifications',
	FEEDBACKS_COLLECTION: 'Feedbacks',
	SURVEYS_COLLECTION: 'Surveys',
	STRIPE_PRODUCTS: 'stripe_products',
	PRODUCT_PRICES: 'prices',
	STRIPE_CHECKOUT_SESSIONS: 'checkout_sessions',
	REFRESH_TOKEN: 'forceTokenRefresh',
	RESUMES: 'resumes',
	INTERVIEWS: 'interviews',
	COVER_LETTERS: 'cover_letters',
	SERVICES: 'services',
	CONTACTS: 'contacts',
	BLOGS: 'blogs',
	BLOG_TEMPLATE: 'blog_templates',
	BLOG_CATEGORIES: 'blog_categories',
	GHOST_JOBS: 'GhostJobs'
}
