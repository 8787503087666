import { StrictMode } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import './index.css'
import theme from './theme'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
	<StrictMode>
		<ThemeProvider theme={theme}>
			{/* MUI CssBaseline */}
			<CssBaseline />
			<App />
		</ThemeProvider>
	</StrictMode>
)
