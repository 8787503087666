import { FC, ChangeEvent, ClipboardEventHandler } from 'react'
import { TextField, Typography } from '@mui/material'

interface TextFieldProps {
	name: string
	placeholder: string
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	value: string | number
	rows?: number
	width?: string
	onPaste?: ClipboardEventHandler<HTMLDivElement>
	type?: string
	onEnter?: () => void
	required?: boolean
	disabled?: boolean
}

export const CustomTextFieldSX = {
	fontWeight: { sm: '400', xs: '500' },
	border: { xs: '1.5px solid #F9F9F9', sm: 'none' },
	borderRadius: { xs: '8px', sm: '0px' },
	fontSize: '14px',
	lineHeight: '22px',
	letterSpacing: '0.1px',
	fontFamily: { sm: 'roboto' },
	width: '100%'
}

const CustomTextField: FC<TextFieldProps> = ({ name, placeholder, rows, onChange, value, onPaste, type = 'text', onEnter, width, required, disabled }) => {
	return (
		<>
			{name !== '' && (
				<Typography
					component="p"
					sx={{
						color: '#171725',
						fontWeight: { sm: '600', xs: '700' },
						fontSize: { sm: '18px', xs: '14px' },
						lineHeight: { sm: '27px', xs: '18px' },
						marginBottom: { sm: '16px', xs: '10px' },
						width: '100%'
					}}
				>
					{name}
				</Typography>
			)}

			<TextField
				required={required ?? false}
				fullWidth
				multiline={!!rows}
				rows={rows}
				value={value}
				onChange={onChange}
				type={type}
				sx={{
					backgroundColor: { sm: '#F6F6F6;' },
					paddingY: { md: '20px', xs: '9px' },
					paddingX: { sm: '16px', xs: '14px' },
					fontWeight: { sm: '400', xs: '500' },
					border: { xs: '1.5px solid #F9F9F9', sm: 'none' },
					borderRadius: { xs: '8px', sm: '0px' },
					fontSize: '14px',
					lineHeight: '22px',
					letterSpacing: '0.1px',
					fontFamily: { sm: 'roboto' },
					width: width ?? '100%'
				}}
				placeholder={placeholder}
				variant="standard"
				InputProps={{
					disableUnderline: true // <== added this
				}}
				onPaste={onPaste}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						if (onEnter) {
							onEnter()
						}
					}
				}}
				disabled={disabled}
			/>
		</>
	)
}

export default CustomTextField
