import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import React from 'react'

export interface SummeryTableColumn {
	name: string
	key: string
	format?: any
	align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
}

export interface SummeryTableRow {
	[key: string]: React.ReactNode
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	},
	'#col-head-0': {
		width: '15%'
	},
	'#col-head-1': {
		width: '10%'
	},
	'#col-head-2': {
		width: '41%'
	},
	'#col-head-4': {
		width: '29%'
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	},

	'.col-data-0': {
		width: '15%'
	},
	'.col-data-1': {
		width: '10%'
	},
	'.col-data-2': {
		width: '41%'
	},
	'#col-head-3': {
		width: '29%'
	},
	td: {
		padding: 0,
		paddingLeft: 16,
		height: 'auto'
	}
}))

const StyledTableCellMobile = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const StyledTableRowMobile = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	},
	'.col-data-2': {
		padding: '0.1rem',
		lineHeight: '1rem'
	},
	td: {
		padding: 0,
		paddingLeft: 16,
		height: 'auto'
	}
}))

const MultiSummeryTableListing: React.FC<{
	columns: SummeryTableColumn[]
	rows: any
	isMobile?: boolean
}> = ({ columns, rows, isMobile }) => {
	return (
		<TableContainer component={Paper}>
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						{columns.map((column, i) => {
							return isMobile ? (
								<StyledTableCellMobile id={`col-head-${i}`} key={i} align={column.align}>
									{column.name}
								</StyledTableCellMobile>
							) : (
								<StyledTableCell id={`col-head-${i}`} key={i} align={column.align}>
									{column.name}
								</StyledTableCell>
							)
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows?.map((row: any, rowIndex: number) => {
						return !isMobile ? (
							<StyledTableRow key={rowIndex}>
								{columns.map((column, i) => {
									return (
										<StyledTableCell className={`col-data-${i}`} key={i} align={column.align}>
											{column.format ? column.format(row[column.key], rowIndex) : row[column.key]}
										</StyledTableCell>
									)
								})}
							</StyledTableRow>
						) : (
							<StyledTableRowMobile key={rowIndex}>
								{columns.map((column, i) => {
									return (
										<StyledTableCellMobile className={`col-data-${i}`} key={i} align={column.align}>
											{column.format ? column.format(row[column.key], rowIndex) : row[column.key]}
										</StyledTableCellMobile>
									)
								})}
							</StyledTableRowMobile>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default MultiSummeryTableListing
