import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { FC, useRef } from 'react'
import ShowNotifications from '../ShowNotifications'
import { useNavigate } from 'react-router'
import { Add } from '@mui/icons-material'
import { ROUTES } from '../../constants/routes'
import { DocumentData, FirestoreError, QuerySnapshot } from 'firebase/firestore'
import { motion } from 'framer-motion'
import { alertInterface, container, item } from '../OverviewComponents/OverviewConstants'
import DesktopGhostJobBar from './GhostJobBar/DesktopGhostJobBar'

interface props {
	ghostJobs: QuerySnapshot<DocumentData, DocumentData> | undefined
	jobsLoading: boolean
	jobsError: FirestoreError | undefined
}

const DesktopGhostJobs: FC<props> = ({ ghostJobs, jobsLoading, jobsError }) => {
	const navigate = useNavigate()

	const alertDetail = useRef<alertInterface>({
		open: false,
		title: '',
		detail: '',
		color: 'warning'
	})
	return (
		<Box
			sx={{
				minHeight: '100vh',
				backgroundColor: '#DFDFDF',
				position: 'relative',
				width: '100%'
			}}
		>
			<Box sx={{ display: 'flex', minHeight: screen.height - 70, width: '100%' }}>
				<Box
					className="job-list"
					sx={{
						width: '100%',
						overflow: 'scroll',
						paddingX: { lg: '40px', xs: '20px' }
					}}
				>
					<ShowNotifications />
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 0 20px 0' }}>
						<Typography
							variant="h2"
							sx={{
								fontWeight: '600',
								fontSize: { lg: '24px', md: '20px', xs: '18px' },
								lineHeight: '36px',
								letterSpacing: '0.1px'
							}}
						>
							Ghost Job Analysis
						</Typography>
						<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
							<Button
								onClick={() => {
									navigate(ROUTES.GHOST_JOB_BUILDER)
								}}
								sx={{
									fontSize: { lg: '14px', sm: '12px' },
									lineHeight: '21px',
									letterSpacing: '0.1px',
									backgroundColor: '#0062FF',
									borderRadius: '10px',
									textTransform: 'none'
								}}
								variant="contained"
								startIcon={<Add />}
							>
								New Ghost Job Analysis
							</Button>
						</Box>
					</Box>
					{!jobsLoading ? (
						<>
							{!jobsError && ghostJobs && ghostJobs.docs.length > 0 ? (
								<motion.div variants={container} initial="hidden" animate="show">
									<Box
										className="job-list"
										sx={{
											paddingBottom: '20px',
											maxWidth: '100%',
											overflow: 'scroll'
										}}
										component={motion.div}
										variants={item}
									>
										{ghostJobs.docs.map((job) => {
											return (
												<DesktopGhostJobBar
													key={job.id}
													ghostPercentage={job.data().ghost_percentage}
													jobId={job.id}
													jobTitle={job.data().job_title}
													companyName={job.data().company_name}
													postDate={job.data().post_date}
												/>
											)
										})}
									</Box>
								</motion.div>
							) : (
								<>
									<Typography
										variant="h2"
										sx={{
											fontWeight: '600',
											fontSize: { lg: '24px', md: '20px', xs: '18px' },
											marginTop: {
												lg: alertDetail.current.open ? '30px' : '42px',
												sm: '28px',
												xs: '28px'
											},
											textAlign: 'center',
											lineHeight: '36px',
											letterSpacing: '0.1px',
											marginBottom: { lg: '30px', xs: '15px' }
										}}
									>
										No Ghost jobs Found.
									</Typography>
								</>
							)}
						</>
					) : (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								mt: '30px'
							}}
						>
							<Typography
								sx={{
									color: '#0062FF',
									fontStyle: 'normal',
									fontWeight: '600',
									fontSize: { md: '24px', sm: '20px', xs: '18px' },
									lineHeight: '36px',
									letterSpacing: '0.15px'
								}}
								component={'p'}
							>
								Loading Ghosts Jobs Please wait ...
							</Typography>
							<Box
								sx={{
									marginTop: '20px'
								}}
								component={'div'}
							>
								<CircularProgress />
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default DesktopGhostJobs
