import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { FC } from 'react'
import Logo from '../../../assets/Navbars/Logo.png'
import { ghostThreshold } from '../GhostJobDetail/constants'
import Calendar from '../../../assets/JobDetail/Calendar.png'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../constants/routes'

interface props {
	jobTitle: string
	jobId: string
	ghostPercentage: number
	postDate: string
	companyName?: string
}

const MobileGhostJobBar: FC<props> = ({ jobTitle, jobId, companyName, ghostPercentage, postDate }) => {
	const navigate = useNavigate()
	return (
		<Box
			sx={{
				minHeight: '150px',
				backgroundColor: 'white',
				marginBottom: '10px',
				padding: '14px',
				borderRadius: '8px',
				boxShadow: '0px 4px 100px rgba(184, 186, 198, 0.1)',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							width: '48px',
							height: '48px',
							backgroundColor: '#F8F8FD',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '10px',
							marginRight: '14px'
						}}
					>
						<Box component="img" sx={{ width: '24px', height: '24px' }} src={Logo} />
					</Box>
					<Box>
						<Typography
							variant="h5"
							sx={{
								color: '#1B2124',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '18px',
								marginBottom: '6px',
								whiteSpace: { lg: 'auto', xs: 'nowrap', sm: 'nowrap' },
								width: { lg: 'auto', xs: '100px', sm: '100px' },
								overflow: { lg: 'auto', xs: 'hidden', sm: 'hidden' },
								textOverflow: { lg: 'auto', xs: 'ellipsis', sm: 'ellipsis' }
								// border: { lg: "none", xs: "1px solid #44444F", sm: "1px solid #44444F" },
							}}
						>
							{jobTitle}
						</Typography>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								marginBottom: '4px',
								whiteSpace: { lg: 'auto', xs: 'nowrap', sm: 'nowrap' },
								width: { lg: 'auto', xs: 'auto', sm: '100px' },
								overflow: { lg: 'auto', xs: 'auto', sm: 'hidden' },
								textOverflow: { lg: 'auto', xs: 'auto', sm: 'ellipsis' }
							}}
						>
							{companyName ?? ''}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress thickness={3} variant="determinate" color={ghostPercentage > ghostThreshold ? 'error' : 'success'} value={parseFloat(ghostPercentage.toFixed(1))} size={50} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Typography variant="caption" component="div" color={ghostPercentage > ghostThreshold ? 'error' : 'success'} fontSize={8} fontWeight={'bold'}>
							{`${ghostPercentage.toFixed(1)}%`}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box sx={{ marginBottom: '20px' }}>
				{postDate && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '15px'
						}}
					>
						<Box
							component="img"
							src={Calendar}
							sx={{
								width: '20px',
								height: '20px',
								marginRight: '8px'
							}}
						/>
						<Typography
							paragraph
							sx={{
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								color: '#7F879E',
								margin: '0'
							}}
						>
							{postDate}
						</Typography>
					</Box>
				)}
			</Box>
			<Button
				fullWidth
				variant="outlined"
				onClick={() => {
					navigate(ROUTES.GHOST_JOBS_DETAIL.replace(':id', jobId ?? ''))
				}}
				sx={{
					height: '38px',
					marginBottom: '16px',
					border: '1.5px solid #3860E2',
					borderRadius: '8px',
					fontWeight: '700',
					fontSize: '12px',
					lineHeight: '16px',
					textTransform: 'none',
					marginTop: 'auto'
				}}
			>
				View
			</Button>
		</Box>
	)
}

export default MobileGhostJobBar
