import { Add } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { DocumentData, FirestoreError, QuerySnapshot } from 'firebase/firestore'
import { FC, useRef } from 'react'
import { ROUTES } from '../../constants/routes'
import { useNavigate } from 'react-router'
import { motion } from 'framer-motion'
import { container, item } from '../JobDetailComponents/JobConstants'
import MobileGhostJobBar from './GhostJobBar/MobileGhostJobBar'
import { alertInterface } from '../OverviewComponents/OverviewConstants'

interface props {
	ghostJobs: QuerySnapshot<DocumentData, DocumentData> | undefined
	jobsLoading: boolean
	jobsError: FirestoreError | undefined
}

const MobileGhostJobs: FC<props> = ({ ghostJobs, jobsLoading, jobsError }) => {
	const navigate = useNavigate()

	const alertDetail = useRef<alertInterface>({
		open: false,
		title: '',
		detail: '',
		color: 'warning'
	})
	return (
		<Box className="mobile-container" sx={{ flexGrow: 1, backgroundColor: '#DFDFDF', paddingTop: '15px' }}>
			<Box sx={{ paddingX: '24px' }}>
				<Button
					onClick={() => {
						navigate(ROUTES.GHOST_JOB_BUILDER)
					}}
					variant="contained"
					sx={{ width: '100%', marginBottom: '20px' }}
					startIcon={<Add />}
				>
					New Ghost Job Analysis
				</Button>
				{!jobsLoading ? (
					<>
						{!jobsError && ghostJobs && ghostJobs.docs.length > 0 ? (
							<>
								All Ghost Jobs listed here.
								<motion.div variants={container} initial="hidden" animate="show">
									<Box className="job-list" sx={{ paddingBottom: '20px' }} component={motion.div} variants={item}>
										{ghostJobs.docs.map((job) => {
											return (
												<Box sx={{ marginTop: '20px', marginBottom: '20px' }} component={motion.div} variants={item} key={job.id}>
													<MobileGhostJobBar
														key={job.id}
														ghostPercentage={job.data().ghost_percentage}
														jobId={job.id}
														jobTitle={job.data().job_title}
														companyName={job.data().company_name}
														postDate={job.data().post_date}
													/>
												</Box>
											)
										})}
									</Box>
								</motion.div>
							</>
						) : (
							<>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										paddingTop: { lg: alertDetail.current.open ? '30px' : '42px', sm: '28px', xs: '28px' },
										textAlign: 'center',
										lineHeight: '36px',
										letterSpacing: '0.1px',
										paddingBottom: { lg: '30px', xs: '15px' }
									}}
								>
									No Ghost jobs Found.
								</Typography>
							</>
						)}
					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							mt: '30px'
						}}
					>
						<Typography
							sx={{
								color: '#0062FF',
								fontStyle: 'normal',
								fontWeight: '600',
								fontSize: { md: '24px', sm: '20px', xs: '18px' },
								lineHeight: '36px',
								letterSpacing: '0.15px'
							}}
							component={'p'}
						>
							Loading Active Jobs Please wait ...
						</Typography>
						<Box
							sx={{
								marginTop: '20px'
							}}
							component={'div'}
						>
							<CircularProgress />
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	)
}

export default MobileGhostJobs
