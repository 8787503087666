import { FC } from 'react'
import ShowSurveys from '../../components/ShowSurveys'
import DesktopGhostJobs from '../../components/GhostJobs/DesktopGhostJobs'
import MobileGhostJobs from '../../components/GhostJobs/MobileGhostJobs'
import { CircularProgress, useMediaQuery } from '@mui/material'
import theme from '../../theme'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../firebase'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection } from 'firebase/firestore'
import { COLLECTION_NAMES } from '../../constants/collections'

const GhostJobs: FC = () => {
	const [user, userLoading] = useAuthState(auth)

	if (userLoading) return <CircularProgress />
	if (!userLoading && !user) {
		toast.error('User not logged in')
		return <Navigate to={ROUTES.LOGIN} />
	}

	const [ghostJobs, loading, error] = useCollection(collection(db, COLLECTION_NAMES.USERS_COLLECTION, user?.uid ?? '', COLLECTION_NAMES.GHOST_JOBS))

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<>
			<ShowSurveys />
			{!isMobile ? <DesktopGhostJobs ghostJobs={ghostJobs} jobsLoading={loading} jobsError={error} /> : <MobileGhostJobs ghostJobs={ghostJobs} jobsLoading={loading} jobsError={error} />}
		</>
	)
}

export default GhostJobs
