// a function that generates hash name for file

import { addDoc, collection } from 'firebase/firestore'
import { COLLECTION_NAMES } from '../constants/collections'
import { referEmailTemplate } from '../constants/emailTemplate'
import { auth, db } from '../firebase'

// from uid + HARD_CODED_STRING + original file name
export const generateFilename = (file: File, jobId: string): string => {
	const fileName = file.name
	const splitted = fileName.split('.')
	const ext = splitted[splitted.length - 1]
	const destinationName = 'resumes/' + jobId + `.${ext}`
	return destinationName
}

export const makeid = (length: number) => {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length
	let counter = 0
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
		counter += 1
	}
	return result
}

export const capitalizeFirstLetter = (stringToCapitalize: string): string => stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)

export const calculateAveragePercentage = (skillsOnly: boolean, percentHard: number, percentSoft: number, percentSales: number): number => {
	let n = 2
	let sum = percentHard + percentSoft
	if (!skillsOnly) {
		sum += percentSales
		n = 3
	}
	const result = sum / n
	return Number(result.toFixed(2))
}

export const referSite = async (link: string, subject: string, message: string, email: string): Promise<void> => {
	let html = referEmailTemplate.replace('{link}', link)
	html = html.replace('{message}', message.length !== 0 ? message : 'You have been referred Gambit Resume platform by ' + (auth?.currentUser?.displayName ?? 'anonymous'))
	const colRef = collection(db, COLLECTION_NAMES.MAIL_COLLECTION)
	await addDoc(colRef, {
		to: email,
		message: {
			subject: subject.length !== 0 ? subject : 'Gambit Resume Referral',
			html
		}
	})
}

export const isUserPremium = async (): Promise<boolean> => {
	await auth.currentUser?.getIdToken(true)
	const decodedToken = await auth?.currentUser?.getIdTokenResult(true)

	return !!decodedToken?.claims?.stripeRole
}

export const getFirScoreColor = (value: number): string => {
	let color = '#60a357' // green
	if (value < 80 && value >= 70) {
		color = '#eac548' // yellow
	} else if (value < 70) {
		color = '#a83241' // red
	}
	return color
}

// from uid + HARD_CODED_STRING + original file name
export const generateBlogFileName = (file: File, name: string): string => {
	const fileName = file.name
	const splitted = fileName.split('.')
	const ext = splitted[splitted.length - 1]
	const destinationName = 'blogs/' + name + `.${ext}`
	return destinationName
}
