import { Dayjs } from 'dayjs'

export enum JobSourcesEnum {
	WEBSITE = 'website',
	SMALL_HEADHUNTING = 'small_headhunting',
	LARGE_HEADHUNTING = 'large_headhunting',
	JOB_BOARD = 'job_board'
}

const jobSources = [
	{
		label: "Company's Website",
		id: JobSourcesEnum.WEBSITE
	},
	{
		label: 'Small Headhunting Company',
		id: JobSourcesEnum.SMALL_HEADHUNTING
	},
	{
		label: 'Large Headhunting Company',
		id: JobSourcesEnum.LARGE_HEADHUNTING
	},
	{
		label: 'Job Board (e.g., LinkedIn, Indeed, etc.)',
		id: JobSourcesEnum.JOB_BOARD
	}
]
export enum SalaryTypesEnum {
	HOURLY = 'hourly',
	ANNUALLY = 'annually'
}

const salaryTypes = [
	{
		label: 'Hourly',
		id: SalaryTypesEnum.HOURLY
	},
	{
		label: 'Annually',
		id: SalaryTypesEnum.ANNUALLY
	}
]

export interface FormValues {
	companyUnknown: boolean
	companyName?: string | null
	salaryUnknown: boolean
	salaryRange?: { min: number; max: number } | null
	salaryType?: SalaryTypesEnum | null
	jobTitle: string
	jobDescription: string
	postDate: Dayjs
	companySize?: number | null
	companySizeUnknown: boolean
	jobSource: JobSourcesEnum
}

export { salaryTypes, jobSources }
