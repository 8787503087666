import { FC, useEffect, useState } from 'react'
import { DocumentData, getDocs } from 'firebase/firestore'
import { getSurveysQuery } from '../../utils/queries'
import SurveyModal from '../SurveyModal'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

const ShowSurveys: FC = () => {
	const [user, loading] = useAuthState(auth)
	const [activeSurveys, setActiveSurveys] = useState<DocumentData[]>([])

	useEffect(() => {
		if (user?.uid) {
			getDocs(getSurveysQuery())
				.then((surveys) => {
					const newSurveys = surveys.docs.filter((doc) => !((doc.data().usersAttempted as string[])?.includes(user.uid) ?? false))
					setActiveSurveys(newSurveys)
				})
				.catch((err) => console.log(err))
		}
	}, [user?.uid, loading])
	return <>{activeSurveys.length !== 0 && user !== undefined && user !== null && activeSurveys.map((doc, idx) => <SurveyModal key={doc.id} survey={doc} uid={user.uid} zIndex={`${idx + 1}`} />)}</>
}

export default ShowSurveys
