import { Typography } from '@mui/material'
import { FC } from 'react'

const FormInputLabel: FC<{ label: string }> = ({ label }) => {
	return (
		<>
			{label !== '' && (
				<Typography
					component="p"
					sx={{
						color: '#171725',
						fontWeight: { sm: '600', xs: '700' },
						fontSize: { sm: '18px', xs: '14px' },
						lineHeight: { sm: '27px', xs: '18px' },
						marginBottom: { sm: '16px', xs: '10px' },
						width: '100%'
					}}
				>
					{label}
				</Typography>
			)}
		</>
	)
}

export default FormInputLabel
