import { FC, HTMLInputTypeAttribute } from 'react'
import { CustomTextFieldSX } from '../../../AddJobDialog/CustomTextField'
import { RegisterOptions, TextFieldElement } from 'react-hook-form-mui'
import FormInputLabel from './FormInputLabel'
import { SxProps } from '@mui/material'

interface FormInputWithValidationProps {
	label: string
	name: string
	placeholder?: string
	rows?: number
	type?: HTMLInputTypeAttribute
	onEnter?: () => void
	required?: boolean
	disabled?: boolean
	error?: boolean
	helperText?: string
	rules: RegisterOptions
	noLabel?: boolean
	sx?: SxProps
}

const FormInputWithValidation: FC<FormInputWithValidationProps> = ({ label, disabled, helperText, error, required, rules, name, type, placeholder, rows, noLabel, sx }) => {
	return (
		<>
			{!noLabel && <FormInputLabel label={label} />}

			{
				<TextFieldElement
					rows={rows}
					multiline={!!rows}
					sx={{ ...CustomTextFieldSX, ...(sx ?? {}) }}
					name={name}
					label={label}
					type={type ?? 'text'}
					placeholder={placeholder ?? ''}
					disabled={disabled}
					error={error}
					helperText={helperText}
					required={required}
					rules={rules}
				/>
			}
		</>
	)
}

export default FormInputWithValidation
