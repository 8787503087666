import { FC, useMemo, useState } from 'react'
import { Job } from '../../models/Job'
import { formatedDate } from '../../utils/functions'
import MultiSummeryTableListing, { SummeryTableColumn } from './MultiSummeryTableListing'
import { Box, Button } from '@mui/material'
import { calculateAveragePercentage, getFirScoreColor } from '../../utils'

const MultiResumesSummeryTable: FC<{ resumes: Job[] | undefined; onView: (isShow: boolean, index: number) => void; isHardSkill: boolean | undefined; isMobile?: boolean }> = ({
	resumes,
	onView,
	isHardSkill,
	isMobile
}) => {
	const [viewIndex, setViewIndex] = useState(-1)

	const columns: SummeryTableColumn[] = [
		{
			name: 'Fit Score',
			key: 'fitScore',
			align: 'left',
			format: (value: any) => {
				let color = '#000'
				if (typeof value === 'number') {
					color = getFirScoreColor(value)
				}
				return (
					<>
						<Box
							sx={{
								width: '70px',
								height: '70px',
								background: color,
								color: '#fff',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '3px',
								borderRadius: '10px',
								fontWeight: 'bold'
							}}
						>
							{value}
						</Box>
					</>
				)
			}
		},
		{
			name: 'App Date',
			key: 'creation_date',
			align: 'left'
		},
		{
			name: 'Candidate Info',
			key: 'candidateInfo',
			align: 'left',
			format: (value: any) => {
				return (
					<div
						style={{
							padding: '10px'
						}}
					>
						{value?.name}
						<br />
						{value?.phone}
						<br />
						{value?.email}
					</div>
				)
			}
		},
		{
			name: 'Action',
			key: 'action',
			align: 'left',
			format: (_: any, index: number) => {
				return (
					<>
						<Button
							variant="outlined"
							color={viewIndex === index ? 'secondary' : 'info'}
							size="small"
							sx={{
								borderRadius: '20px'
							}}
							onClick={() => {
								if (viewIndex === index) {
									setViewIndex(-1)
									onView(false, 0)
								}
								if (viewIndex !== index) {
									setViewIndex(index)
									onView(true, index)
								}
							}}
						>
							View
						</Button>
					</>
				)
			}
		}
	]
	const summeryResumes = useMemo(() => {
		return resumes?.map((job) => {
			return {
				fitScore: isHardSkill ? job.percentage_hard : calculateAveragePercentage(false, job.percentage_hard, job.percentage_soft, job.percentage_sales),
				creation_date: formatedDate(job.created ? job.created.toDate() : new Date()),
				candidateInfo: {
					name: job.resumeCandidateName ?? '',
					phone: job.resumePhone ?? '',
					email: job.resumeEmail ?? '',
					title: job.resumeTitle ?? '',
					city: job.resumeCity ?? ''
				},
				action: null
			}
		})
	}, [resumes])
	return (
		<>
			<MultiSummeryTableListing isMobile={isMobile} columns={columns} rows={summeryResumes} />
		</>
	)
}

export default MultiResumesSummeryTable
