import { ChangeEvent, FC, useContext, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import CustomTextField from './CustomTextField'
import { useAuthState } from 'react-firebase-hooks/auth'
import { db, auth, storage } from '../../firebase'
import { collection, addDoc, serverTimestamp, Timestamp, DocumentData, DocumentReference, doc, getDoc } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import { generateFilename } from '../../utils'
import { analyzeJobDescription } from '../../utils/job'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import AddJobDialogFields from './AddJobDialogFields'
import { ResumeInputMode, ScannedResume, defaultResume } from '../JobDetailComponents/types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLLECTION_NAMES } from '../../constants/collections'
import { ResumeType } from '../../models/Resume'
import { convertResumeToText } from '../../utils/resumes'
import AddJobDialogFooter from './AddJobDialogFooter'
import AddResumeButton from './AddResumeButton'
import AddSkillsCheckbox from './AddSkillsCheckbox'
import { defaultResume as defaultResumeTwo } from '../../models/Resume'
import { ROUTES } from '../../constants/routes'
import { useNavigate } from 'react-router'
import UploadResumes from '../JobDetailComponents/UploadResumes'
import { getGptData } from '../../utils/httpTrigger'
import { LoaderContext } from '../../contexts/loader-context'
import FilenameView from './FilenameView'

const AddJobDialog: FC<{ isMultipleResumes: boolean }> = ({ isMultipleResumes }) => {
	const [user, userLoading, error] = useAuthState(auth)
	const navigator = useNavigate()
	const { anonymousJobs, setAnonymousJobs, hasAnonymousJobs, setHasAnonymousJobs } = useContext(AnonymousJobsCTX)
	const [resumesSnap] = useCollection(user ? collection(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES) : null)
	const { setShowLoading } = useContext(LoaderContext)

	const resumesList = useMemo(() => {
		if (!resumesSnap) return []
		const list = resumesSnap.docs.map((doc) => ({ id: doc.id, name: (doc.data() as ResumeType).resumeName }))
		return list
	}, [resumesSnap])

	const [title, setTitle] = useState<string>('')
	const [company, setCompany] = useState<string>('')
	const [location, setLocation] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [moreInfo, setMoreInfo] = useState<string>('')
	const [skillsOnly, setSkillsOnly] = useState<boolean>(false)
	const [scannableResumesList, setScannableResumesList] = useState<ScannedResume[]>([{ ...defaultResume }])

	const descChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value)
		analyze(e.target.value)
	}

	const analyze = (text: string) => {
		const { analyzedTitle, analyzedCompany, analyzedLocation, analyzedMoreInfo } = analyzeJobDescription(text)
		setTitle(analyzedTitle)
		setCompany(analyzedCompany)
		setLocation(analyzedLocation)
		setMoreInfo(analyzedMoreInfo)
	}
	const handleScannedResumeChanges = (value: string | ResumeInputMode | File | null | boolean, index: number, key: string) => {
		const updateScannedResumeList = scannableResumesList.map((resume, scanResumeIndex) => (scanResumeIndex === index ? { ...resume, [key]: value } : resume))
		setScannableResumesList(updateScannedResumeList)
	}
	const handleAddResume = (file?: File, addToMap?: boolean) => {
		if (file)
			return setScannableResumesList((scannedResumeList) => [
				...scannedResumeList,
				{ ...defaultResume, newResume: true, resumeMode: 'file', file, fileName: file ? file.name : '', multipleSelected: addToMap }
			])
		setScannableResumesList((scannedResumeList) => [...scannedResumeList, { ...defaultResume, newResume: true }])
	}

	const handleDeleteScannedResume = (index: number) => {
		const scannableResumesListCopy = scannableResumesList.filter((item, i) => i !== index)
		setScannableResumesList(scannableResumesListCopy)
	}

	const updateResumeMetadata = (index: number, key: string, value: string) => setScannableResumesList((prev) => prev?.map((item, i) => (i === index ? { ...item, [key]: value } : item)))

	const saveJob = async (status: 'draft' | 'in progress') => {
		try {
			setShowLoading(true)
			if (!userLoading && error == null && user !== null && user !== undefined) {
				const groupId = user.uid + String(Math.random() * 10000000)
				let gptData: any = ''
				if (description) {
					const result = await getGptData(description)
					gptData = JSON.stringify((await result.json()).result)
				}
				const jobs = scannableResumesList.map((resume) => {
					const groupData: { [key: string]: string | Timestamp | string[] | number | Object | null } = {
						...defaultResumeTwo,
						uid: user.uid,
						email: user.email ? user.email : 'anonymous',
						displayName: user.displayName ? user.displayName : 'anonymous',
						title,
						gpt_data: gptData,
						company,
						location,
						description,
						moreInfo,
						created: serverTimestamp() as Timestamp,
						lastMod: serverTimestamp() as Timestamp,
						status,
						resume: resume.resumeMode === 'file' ? '' : resume.jobText,
						sharedWith: user.isAnonymous ? [] : [user.email ? user.email : ''],
						skillsOnly,
						selectedResume: resume.selectedResume,
						resumeMode: resume.resumeMode,
						resumeCandidateName: resume.resumeCandidateName ?? '',
						resumeTitle: resume.resumeTitle ?? '',
						resumeCity: resume.resumeCity ?? '',
						resumePhone: resume.resumePhone ?? '',
						resumeSalary: resume.resumeSalary ?? ''
					}

					if (isMultipleResumes) groupData.groupId = groupId
					return {
						file: resume.file,
						data: groupData
					}
				})

				const jobsPromise = jobs
					.map((job) => {
						if (job.data.resumeMode === 'resumes') {
							return new Promise((resolve) => {
								getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES, job.data.selectedResume as string)).then((resumeDoc) => {
									if (resumeDoc.exists()) {
										const data = resumeDoc.data()
										const resumeData = convertResumeToText(data as ResumeType)
										job.data.resume = resumeData
									} else {
										job.data.selectedResume = ''
									}
									resolve(true)
								})
							})
						}
						return null
					})
					.filter((uploadPromise) => uploadPromise)

				await Promise.all(jobsPromise)
				const addJobPromises = jobs.map(async (job) => {
					return await new Promise<{ docRef: DocumentReference<DocumentData>; job: { file: File | null; data: { [key: string]: string | Timestamp | string[] | number | Object | null } } }>(
						(resolve) => {
							addDoc(collection(db, 'Jobs'), job.data).then((docRef) =>
								resolve({
									docRef,
									job
								})
							)
						}
					)
				})
				const responses = await Promise.all(addJobPromises)
				const uploadResponsesPromises = responses
					.map(({ docRef, job }) => {
						if (user.isAnonymous && setAnonymousJobs && anonymousJobs) {
							setAnonymousJobs([...anonymousJobs, docRef.id])
							if (!hasAnonymousJobs && setHasAnonymousJobs) setHasAnonymousJobs(true)
						}
						if (job.data.resumeMode === 'file') {
							if (job.file !== null) {
								const destinationName = generateFilename(job.file, docRef.id)
								const resumeRef = ref(storage, destinationName)
								return uploadBytes(resumeRef, job.file)
							} else console.warn('No resume provided')
						}
						return null
					})
					.filter((resumeUpload) => resumeUpload)
				if (uploadResponsesPromises && uploadResponsesPromises.length > 0) await Promise.all(uploadResponsesPromises)
				setShowLoading(false)
				navigator(isMultipleResumes ? ROUTES.MULTIPLE_RESUMES_JOBS : ROUTES.JOBS)
			}
		} catch (error) {
			setShowLoading(false)
			console.warn('No job type selected')
			navigator(isMultipleResumes ? ROUTES.MULTIPLE_RESUMES_JOBS : ROUTES.JOBS)
			alert('No job type selected !System bug detected')
		}
	}

	return (
		<Box
			sx={{
				backgroundColor: 'white',
				bordeRadius: 20,
				margintop: 19,
				width: '100%',
				padding: '40px',
				paddingBottom: '0px',
				borderRadius: '20px'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '30px', xs: '0px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' }
					}}
				>
					<CustomTextField name="Job Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Job Title" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginLeft: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="Company Name" value={company} onChange={(e) => setCompany(e.target.value)} placeholder="Company Name" />
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '20px', xs: '15px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="Location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Location" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginLeft: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="More Info" value={moreInfo} onChange={(e) => setMoreInfo(e.target.value)} placeholder="More Info" />
				</Box>
			</Box>
			<Box
				sx={{
					marginBottom: { md: '48px', sm: '20px' },
					marginTop: { sm: '0', xs: '15px' }
				}}
			>
				<CustomTextField width="100%" rows={10} name="Job Description" value={description} onChange={descChangeHandler} placeholder="Job Description" />
			</Box>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{isMultipleResumes ? (
					<UploadResumes
						handleChange={(e) => {
							if (e.target.files && e.target.files.length > 0) for (let i = 0; i < e.target.files.length; i++) handleAddResume(e.target.files[i], true)
							if (e.target.files && e.target.files.length > 0) alert(`${e.target.files.length} resume(s) added.`)
							e.target.value = ''
						}}
					/>
				) : null}
				<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
					{scannableResumesList.map((resume, index) =>
						resume?.multipleSelected ? <FilenameView key={resume.fileName} fileName={resume.fileName ?? ''} handleRemove={() => handleDeleteScannedResume(index)} /> : null
					)}
				</div>
			</div>
			{scannableResumesList.map((resume, index) => (
				<AddJobDialogFields
					handleDelete={() => handleDeleteScannedResume(index)}
					key={index}
					index={index}
					resumeCount={isMultipleResumes ? index + 1 : 0}
					resumesList={resumesList}
					setSelectedResume={(i, value) => handleScannedResumeChanges(value !== undefined ? value : String(i), index, 'selectedResume')}
					selectedResume={resume.selectedResume}
					resumeMode={resume.resumeMode}
					setResumeMode={(i, value) => handleScannedResumeChanges(value !== undefined ? value : String(i), index, 'resumeMode')}
					jobText={resume.jobText}
					setJobText={(value) => handleScannedResumeChanges(value as string, index, 'jobText')} // handle this function
					file={resume.file}
					setFile={(value) => handleScannedResumeChanges(value as File, index, 'file')}
					isMultipleResumes={isMultipleResumes}
					newResume={resume.newResume}
					resumeName={resume?.resumeCandidateName}
					resumeTitle={resume?.resumeTitle}
					resumeLocation={resume?.resumeCity}
					resumeContact={resume?.resumePhone}
					resumeSalary={resume?.resumeSalary}
					setResumeName={(index, value) => updateResumeMetadata(index, 'resumeCandidateName', value)}
					setResumeTitle={(index, value) => updateResumeMetadata(index, 'resumeTitle', value)}
					setResumeLocation={(index, value) => updateResumeMetadata(index, 'resumeCity', value)}
					setResumeContact={(index, value) => updateResumeMetadata(index, 'resumePhone', value)}
					setResumeSalary={(index, value) => updateResumeMetadata(index, 'resumeSalary', value)}
					fileName={resume?.fileName}
				/>
			))}
			{isMultipleResumes ? <AddResumeButton handleAddResume={handleAddResume} /> : null}
			<AddSkillsCheckbox skillsOnly={skillsOnly} setSkillsOnly={setSkillsOnly} />
			<Box sx={{ marginBottom: '5px', borderBottom: 'solid 1px transparent' }}>
				<AddJobDialogFooter saveJob={saveJob} isDisabled={false} />
			</Box>
		</Box>
	)
}
export default AddJobDialog
