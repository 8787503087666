import { ArrowLeft } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../constants/routes'
import { motion } from 'framer-motion'
import { container, item } from '../../JobDetailComponents/JobConstants'
import GhostJobForm from './Form/GhostJobForm'
import { saveNewGhostJob } from '../../../utils/ghostJobs'
import { User } from 'firebase/auth'
import { FormValues } from './Form/constants'

const AddGhostJob: FC = () => {
	const navigate = useNavigate()

	const saveFunction = async (data: FormValues, user: User) => {
		return await saveNewGhostJob(data, user, navigate)
	}

	return (
		<Box
			sx={{
				position: 'relative',
				minHeight: 'calc(100vh - 60px)',
				backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
				width: '100%'
			}}
		>
			<Box sx={{ display: 'flex' }}>
				<Box
					sx={{
						paddingX: { lg: '40px', sm: '20px', xs: '12px' },
						flex: 'auto'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							padding: '30px 0 20px 0'
						}}
					>
						<Box
							onClick={() => navigate(ROUTES.GHOST_JOBS)}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: '#171725',
								boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
								borderRadius: '50%',
								cursor: 'pointer',
								width: { lg: '46px', sm: '35px', xs: '30px' },
								height: { lg: '46px', sm: '35px', xs: '30px' }
							}}
						>
							<ArrowLeft />
						</Box>
						<Typography
							variant="h2"
							sx={{
								fontWeight: '600',
								fontSize: { lg: '24px', md: '20px', xs: '17px' },
								lineHeight: '36px',
								letterSpacing: '0.1px',
								marginLeft: { sm: '18px', xs: '12px' },
								color: '#171725'
							}}
						>
							Ghost Job Form
						</Typography>
					</Box>
					<motion.div variants={container} initial="hidden" animate="show">
						<Box component={motion.div} variants={item}>
							<Box
								sx={{
									backgroundColor: 'white',
									bordeRadius: 20,
									margintop: 19,
									width: '100%',
									padding: '40px',
									paddingBottom: '0px',
									borderRadius: '20px'
								}}
							>
								<GhostJobForm saveFunction={saveFunction} />
							</Box>
						</Box>
					</motion.div>
				</Box>
			</Box>
		</Box>
	)
}

export default AddGhostJob
